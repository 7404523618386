import React from 'react';
import './home.css';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import { tafqeet } from './Tafqeet';

export default function Home(props) {
  const [day, setDay] = React.useState('');
  const [firstDateAr, setFirstDateAr] = React.useState('');
  const [firstDateEn, setFirstDateEn] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [clientID, setClientID] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [apartment, setApartment] = React.useState('');
  const [direction, setDirection] = React.useState('');
  const [floor, setFloor] = React.useState('');
  const [size, setSize] = React.useState('');
  const [landNumber, setLandNumber] = React.useState('');
  const [scheme, setScheme] = React.useState('');
  const [district, setDistrict] = React.useState('');
  const [instrument, setInstrument] = React.useState('');
  const [instrumentDate, setInstrumentDate] = React.useState('');
  const [guardsmanApartment, setGuardsmanApartment] = React.useState('');
  const [parkingNumber, setParkingNumber] = React.useState('');
  const [tankNumber, setTankNumber] = React.useState('');
  const [priceNumbers, setPriceNumbers] = React.useState('');
  const [priceWords, setPriceWords] = React.useState('');
  const [checkNumber, setCheckNumber] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [checkDate, setCheckDate] = React.useState('');
  const [deliveryTime, setDeliveryTime] = React.useState('');
  const [type, setType] = React.useState('');
  const [years, setYears] = React.useState('');
  const [years2, setYears2] = React.useState('');
  const [nameFirst, setNameFirst] = React.useState('');
  const [nameSecond, setNameSecond] = React.useState('');

  const [contract, setContract] = React.useState(false);

  const [emailFirst, setEmailFirst] = React.useState('');
  const [emailSecond, setEmailSecond] = React.useState('');
  const [representativeName, setRepresentativeName] = React.useState('');
  const [representativeID, setRepresentativeID] = React.useState('1078390000');
  const [powerOfAttorneyNumber, setPowerOfAttorneyNumber] = React.useState('423406767');
  const [powerOfAttorneyDate, setPowerOfAttorneyDate] = React.useState('28/10/1442');
  const [companyAddress, setCompanyAddress] = React.useState('جدة جامعة الملك عبدالعزيز منارات المعرفة مبنى 402 مكتب 219');
  const [nameThird, setNameThird] = React.useState('');

  const handelPriceNumbers = (price) => {
    // console.log(price.length);
    if (price.length === 4) {
      let tempArr = price.split('');
      let tempPop = tempArr.pop();
      tempArr.push(',');
      tempArr.push(tempPop);
      price = tempArr.join('');
    }
    setPriceNumbers(price);
  };
  props.project.forEach((e, key) => {
    for (let [key, value] of Object.entries(e['booking'])) {
      value.forEach((ee, key) => {
        if (
          ee.contractID === parseInt(window.location.pathname.split('/')[2]) &&
          !contract
        ) {
          setContract(ee.contract);
        }
      });
    }
  });

  React.useEffect(() => {
    console.log(contract);
    setDay(contract?.day);
    setFirstDateAr(contract?.firstDateAr);
    setFirstDateEn(contract?.firstDateEn);
    setClientName(contract?.clientName);
    setNationality(contract?.nationality);
    setClientID(contract?.clientID);
    setMobileNumber(contract?.mobileNumber);
    setApartment(contract?.apartment);
    setDirection(contract?.direction);
    setFloor(contract?.floor);
    setSize(contract?.size);
    setLandNumber(contract?.landNumber);
    setScheme(contract?.scheme);
    setDistrict(contract?.district);
    setInstrument(contract?.instrument);
    setInstrumentDate(contract?.instrumentDate);
    setGuardsmanApartment(contract?.guardsmanApartment);
    setParkingNumber(contract?.parkingNumber);
    setTankNumber(contract?.tankNumber);
    setPriceNumbers(contract?.priceNumbers);
    setPriceWords(contract?.priceWords);
    setCheckNumber(contract?.checkNumber);
    setBankName(contract?.bankName);
    setCheckDate(contract?.checkDate);
    setDeliveryTime(contract?.deliveryTime);
    setType(contract?.type);
    setYears(contract?.years);
    setYears2(contract?.years2);
    setNameFirst(contract?.nameFirst);
    setEmailFirst(contract?.emailFirst);
    setEmailSecond(contract?.emailSecond);
    setRepresentativeName(contract?.nameFirst);
    if (contract?.representativeID) {
      setRepresentativeID(contract.representativeID);
    }
    if (contract?.powerOfAttorneyNumber) {
      setPowerOfAttorneyNumber(contract.powerOfAttorneyNumber); 
    }
    if (contract?.powerOfAttorneyDate) {
      setPowerOfAttorneyDate(contract.powerOfAttorneyDate);
    }
    if (contract?.companyAddress) {
      setCompanyAddress(contract.companyAddress);
    }
    if (contract?.nameThird) {
      setNameThird(contract.nameThird);
    }
    props.handelHeader(false);
  }, [contract !== false]);

  const PrintButton = () => {
    return (
      <div className="buttons" style={{
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <button
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            padding: '12px 24px',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'all 0.2s ease',
            ':hover': {
              backgroundColor: '#0056b3',
              transform: 'translateY(-1px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}
          onClick={(e) => {
            let buttonDiv = $('.contractDiv .buttons');
            $('.contractDiv .buttons').remove();
            window.print();
            $('.contractDiv')[0].append(buttonDiv[0]);
            $('.contractDiv')[0].append(buttonDiv[1]);
          }}
        >
          طباعة عادية
        </button>
      </div>
    );
  };

  const PrintBlack = () => {
    return (
      <div className="buttons" style={{
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <button
          style={{
            backgroundColor: '#343a40',
            color: 'white', 
            padding: '12px 24px',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'all 0.2s ease',
            ':hover': {
              backgroundColor: '#23272b',
              transform: 'translateY(-1px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}
          onClick={(e) => {
            let buttonDiv = $('.contractDiv .buttons');
            $('.contractDiv .buttons').remove();
            $('.contractDiv *').addClass('blackPrint');
            window.print();
            $('.contractDiv')[0].append(buttonDiv[0]);
            $('.contractDiv')[0].append(buttonDiv[1]);
            $('.contractDiv *').removeClass('blackPrint');
          }}
        >
          طباعة سوداء
        </button>
      </div>
    );
  };

  return (
    <div>
      {contract ? (
        <>
          {' '}
          <div className="contractDiv">
            {' '}
            <h3>بسم الله الرحمن الرحيم</h3>
            <h3
              className="contractHeader"
              style={{ textDecoration: 'underline' }}
            >
              عقد بيع وحدة سكنية
            </h3>
            <p className="NoSpaceAfter">
              الحمد لله رب العالمين والصلاة والسلام على أشرف المرسلين سيدنا محمد
              صلى الله عليه وسلم وعلى آلة وصحبه أجمعين، وبعد
            </p>
            <div className="p1">
              <p>
                إنه في يوم{' '}
                <span className="inputSpan">
                  {day} بتاريخ {firstDateAr} هـ
                </span>{' '}
                الموافق: <span className="inputSpan">{firstDateEn} م </span>
                بمدينة جدة بعون الله تعالى تم الاتفاق والتراضي بين:{' '}
              </p>
            </div>
            {/* <p>{`إنه في يوم ${day} ${firstDateAr}ه الموافق:  ${firstDateEn}م بمدينة جدة بعون الله تعالى تم الاتفاق والتراضي بين:`}</p> */}
            <p className="NoSpaceAfter">
              <span className="numbering">1.</span> البائع: شركة أوتار العالمية
              للتطوير العقاري سجل تجاري رقم (4030587137) صادر من جدة وعنوانها {companyAddress}{" "}
              وبريدها الالكتروني المعتمد لكافة الاشعارات والتبليغات التي تتم وفقاً لأحكام هذا العقد 
              هو <span className="inputSpan" style={{color: 'red'}}>{emailFirst || '...........................'}</span> ويشار إليه فيما بعد (الطرف الأول) ويمثلها في هذا العقد
              الأستاذ /{representativeName} سجل مدني رقم {representativeID} بموجب
              الوكالة رقم {powerOfAttorneyNumber} وتاريخ {powerOfAttorneyDate}هـ.
            </p>
            <div className="p1">
              <p>
                <span className="numbering">2.</span> المشتري: السيد/ة{' '}
                {clientName} {nationality} الجنسية بموجب هوية رقم (
                {clientID}) وبريده الالكتروني
                ورقم جواله المعتمدين لكافة الاشعارات والتبليغات التي تتم
                وفقاً لأحكام هذا العقد هما <span className="inputSpan" style={{color: 'red'}}>{emailSecond || '...........................'}</span> جوال رقم ({mobileNumber}) ويشار اليها
                فيما بعد (الطرف الثاني).
              </p>
            </div>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>تمهيد:</p>
            <div className="p1">
              <p>
                الطرف الأول هو المالك لـ(الوحدة السكنية المباعة) وهي الوحدة رقم
                ( <span className="inputSpan">{apartment}</span> ) الواقعة في
                الجهة ( <span className="inputSpan">{direction}</span> ) في ({' '}
                <span className="inputSpan">{floor}</span> ) ومساحتها ({' '}
                <span className="inputSpan">{size}</span> ) متر مربع ، والمقامة
                على قيد أرض رقم ({' '}
                  <span className="inputSpan">{landNumber}</span> ) من المخطط رقم ({' '}
                  <span className="inputSpan">{scheme}</span> ) والواقع في حي
                ( <span className="inputSpan">{district}</span> ) بمدينة جدة ؛
                وذلك بموجب صك شرعي بالرقم ({' '}
                <span className="inputSpan">{instrument}</span> ) وتاريخ (
                <span className="inputSpan">{instrumentDate}</span> ) والصادر من
                كتابة العدل الأولى بمدينة جدة ، وحيث رغب الطرف الثاني في شراء
                هذه الشقة وملحقاتها المتمثلة في غرفة الحارس رقم ({' '}
                <span className="inputSpan">{guardsmanApartment}</span> ) و
                الموقف رقم ( <span className="inputSpan">{parkingNumber}</span>{' '}
                ) ، والخزانين المستقلين والمرقمين بالرقم ({' '}
                <span className="inputSpan">{tankNumber}</span> ) أحدهما في
                الأعلى والآخر في الأسفل وقد تم تحديدهم من قبل الطرف الاول. فقد
                اتفق الطرفان بكامل أهليتهما المعتبرة شرعاً ونظاماً على الآتي:
              </p>
            </div>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الأول:</p>
            <p >يعتبر التمهيد أعلاه جزءً لا يتجزأ من العقد يقرأ ويفسر معه.</p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الثاني:</p>
            <p >
              باع الطرف الأول للطرف الثاني الشقة المشار إليها في التمهيد بيعاً
              منجزاً لا رجعة فيه.
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الثالث: (الثمن)</p>
            <div className="p1">
              <p>
                تم البيع بثمن إجمالي ({' '}
                <span className="inputSpan">
                  {priceNumbers}
                </span>{' '}
                ) ريال ({' '}
                  <span className="inputSpan">
                  {priceWords}
                </span>{' '}
                ريال سعودي) استلمه الطرف الأول بموجب شيك رقم ({' '}
                <span className="inputSpan">
                  {checkNumber}
                </span>{' '}
                ) صادر من بنك ({' '}
                <span className="inputSpan">
                  {bankName}
                </span>{' '}
                ) بتاريخ ({' '}
                <span className="inputSpan">
                  {checkDate}
                </span>{' '}
                ) الشيك غير شامل لضريبة التصرفات العقارية التي يلتزم الطرف الثاني بسدادها كاملة قبل أن يتم الإفراغ أمام كاتب العدل  . 
              </p>
            </div>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الرابع:</p>
            <p>
            يقر الطرف الثاني بأنه عاين الشقة المباعة وملحقاتها المعاينة التامة النافية للجهالة وأنه قبلها بحالتها الراهنة، وأنه أصبح مسئولا عنها المسئولية المدنية والجنائية، ولا يحق له الرجوع على الطرف الأول لأي سبب.  
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الخامس:</p>
            <p>
            يحق للطرف الثاني استغلال الشقة المباعة، ولا يحق له التصرف في الأجزاء المشتركة بين جميع الملاك أو المطالبة باي أضافات ا و بموقف أو خزان إضافي أو باستخدام الأسطح السفلية أو العلوية للعمارة . 
            </p>
            <p style={{fontWeight: 'bold'}}>البند السادس:</p>
            <p>
            يجوز للطرف الثاني تأجير الشقة المباعة للعوائل، ولا يجوز له أن يؤجرها لعزاب أو مكاتب. 
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند السابع: (التزامات الطرف الأول)</p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">1.</span>يلتزم الطرف الأول بنقل ملكية الشقة المباعة للطرف الثاني وإفراغها باسمه خالية تماماً من أي رهونات أو حجوزات وغير مشغولة أو مؤجرة لأي طرف آخر، وتنتهي مسئولية الطرف الأول عند الفراغ، على أن يتحمل الطرف الثاني جميع مصروفات الافراغ، ومن المعلوم لدى الطرف الثاني بأنه ملزم بتزويد الطرف الأول بكل ما يحتاجه من مستندات خاصة بالطرف الثاني أو حضور شخصي أو بالوكالة بغرض إتمام نقل ملكية الشقة المباعة أمام الجهات المختصة . 
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">2.</span>يلتزم الطرف الأول بعدم التعرض للطرف الثاني وعدم التصرف في الشقة المباعة وإلا كان من حق الطرف الثاني إبطال هذا التصرف.             
            </p>
            <div className="p1">
              {' '}
              <p>
                <span className="numbering">3.</span> يلتزم الطرف الأول بتسليم
                الشقة المباعة للطرف الثاني بتاريخ{' '}
                <span className="inputSpan">{deliveryTime} هـ</span> ذا استوفى
                كامل المستحقات المالية من الطرف الثاني.
              </p>
            </div>
            <p className="paned_8 NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الثامن: (التزامات الطرف الثاني)</p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">1.</span> يلتزم الطرف الثاني بعدم
              القيام بأي تعديلات على الأجزاء المشتركة أو على الواجهة الرئيسية أو
              الهيكل الإنشائي للعمارة.
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">2.</span> يلتزم الطرف الثاني بالإسراع
              في إصلاح التلفيات والأعطال التي يتسبب فيها سواء بقصد أو بغير قصد
              حفاظا على سلامة المبنى.
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">3.</span>يلتزم الطرف الثاني بسداد
              العوائد وجميع الأموال المقررة نظاماً على الشقة المباعة بعد تاريخ الإفراغ، ويلتزم
              بدفع حصته من اتحاد الملاك أو من استهلاك الكهرباء والمياه الخاص
              بخدمات العمارة، وكل ما تحتاجه العمارة من مصروفات خاصة بالصيانة
              والتجديد والنظافة، كما يلتزم بدفع حصته من راتب حارس العمارة.
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
            <span className="numbering">4.</span>يلتزم الطرف الثاني بنقل ملكية
              العداد الخاص بالوحدة السكنية (عداد الكهرباء) باسمه بعد الإفراغ له
              مباشرة. ويتعهد منفردا ومجتمعا مع ملاك الوحدات الأخرى في العمارة
              بنقل ملكية عداد الكهرباء التابع للخدمات باسم أحد منهم أو باسم
              جمعية الملاك.
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
            <span className="numbering">5.</span> يلتزم الطرف الثاني منفردا
              ومجتمعا مع ملاك الوحدات الأخرى في العمارة بالحفاظ على أساسات
              المبنى والإلتزام بالسحب الدوري للصرف الصحي إن وجد وتركيب عداد المياه الخاص بهم بشكل صحيح وعدم الإضرار بتوصيلات الغير.
            </p>
            <p className="numbered-paragraph NoSpaceAfter">
              <span className="numbering">6.</span> ضريبة التصرفات العقارية على بيع
              الوحدة السكنية يتم سدادها عن طريق الطرف الثاني قبل الإفراغ أو إحضار شيك مصدق بالمبلغ.
            </p>
            <p className='numbered-paragraph NoSpaceAfter'>
              <span className="numbering">7.</span> من المعلوم لدى الطرف الثاني بأن الشقة محل هذا العقد لم يتم تركيب عداد مياه خاصة بها بعد، وعليه فإن الطرف الثاني يعلم ويقبل بذلك ويلتزم على مسؤوليته ونفقته المنفردة بإنهاء كافة الإجراءات اللازمة لتركيب عداد المياه الخاص بالشقة محل عقد البيع ومراجعة كافة الجهات الحكومية المختصة بذلك، وأي مسؤولية أو التزام على الطرف الأول بهذا الخصوص أمام الجهات الحكومية أو البلدية تنتقل وتصبح على عاتق ومسؤولية المشتري بمجرد التوقيع على هذا العقد، وينحصر دور الطرف الأول في مساعدة الطرف الثاني وتزويده بأي مستندات مطلوبة من طرفه لإنهاء هذه الإجراءات في حال كانت موجودة لديه وعليه في حال عادت أي جهة حكومية على الطرف الأول بأي من هذه الإجراءات فإن الطرف الثاني هو الملزم بالتصدي لها على مسؤوليته المنفردة.
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند التاسع: (الضمان)</p>
            <div className="p1">
              <p className="indentText NoSpaceAfter">
                <span className="numbering">1.</span> يضمن الطرف الأول أعمال
                السباكة والكهرباء المخفية ({' '}
                <span className="inputSpan">{type}</span> ) لمدة ({' '}
                <span className="inputSpan">{years}</span> ) والأعمال الخرسانية لمدة
                ( <span className="inputSpan">{years2}</span> ) من تاريخ هذا
                العقد. ولا يشمل هذا الضمان التلفيات والأعطال التي سببها الطرف
                الثاني او التلفيات والأعطال الناتجة عن الكوارث الطبيعية والقوارض
                والحشرات وما شابهه وتسقط أي التزامات على الطرف الاول في حال قام العميل بعمل أي إصلاحات او إضافات أو تعديلات شخصيه في الوحدة العقارية.
              </p>
            </div>
            <p className="indentText">
              <span className="numbering">2.</span> الضمان الخاص بالمصعد أو
              أعمال الخرسانة يكون لدى الشركة الموردة حسب أنظمتها واشتراطاتها،
              ولا يشمل التلفيات والأعطال الناتجة عن الكوارث الطبيعية والقوارض
              والحشرات وما شابهه، ولا يشمل التعديلات التي يقوم بها الطرف الثاني
              في الشقة أو العمارة.
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند العاشر:</p>
            <p className="NoSpaceAfter">
              <span className="numbering">1.</span> تسري بنود هذا العقد على كل
              من يشتري الشقة المباعة أو يستأجرها من الطرف الثاني.
            </p>
            <p>
              <span className="numbering">2.</span> لا يعتبر هذا العقد صحيحاً ما
              لم يحتوي على توقيع الأطراف.
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الحادي عشر:</p>
            <p>
            يخضع هذا العقد لأنظمة المملكة العربية السعودية وفي حال نشأ نزاع بشأن تنفيذ أو تفسير أو صحته أو نفاذه فتم حله عن طريق الجهات القضائية المختصة بمدينة جدة. 
            </p>
            <p className="NoSpaceAfter" style={{fontWeight: 'bold'}}>البند الثاني عشر:</p>
            <p>
              حرر هذا العقد من نسختين أصليتين مختومتين بيد كل طرف نسخة للعمل
              بموجبها بعد أن قرأها الطرف الثاني وعلم بمحتواها علما نافيا للجهالة
              والغرر والغبن.
            </p>
            <div className="t12">
              <div>
                <p>الطرف الأول:  شركة أوتار العالمية للتطوير العقاري  </p>
                <p>الاسم: {nameFirst}</p>
                <p className="signature">التوقيع:</p>
              </div>

              <div className="t2">
                <p>الطرف الثاني:</p>
                <p>الاسم: {nameThird !== "" ? nameThird : clientName}</p>
                <p className="signature">التوقيع:</p>
              </div>
            </div>
            {/* <p>الشهود:</p>
            <pre className="shahed shahed_1">{`1) ...................... هويةرقم (                   ) جوال (                  ) التوقيع ...............`}</pre>
            <pre className="shahed">{`2) ...................... هويةرقم (                   ) جوال (                  ) التوقيع ...............`}</pre> */}
            <PrintButton />
            <PrintBlack />
          </div>
        </>
      ) : (
        <>
          <h4>لايوجد عقد</h4>
        </>
      )}
      {/* <button onClick={(e) => window.print()}>طباعة</button> */}
    </div>
  );
}
